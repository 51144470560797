<template>
  <base-mobile-page>
    <template v-slot:main>
        <el-main v-loading="loading" style="margin:20px auto;max-width:1100px;width:100%;">
          <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:0px 5px;">
            <el-breadcrumb-item to="/account">My Account</el-breadcrumb-item>
            <el-breadcrumb-item :to="backToOrderLink">Order Detail</el-breadcrumb-item>
            <el-breadcrumb-item>Review</el-breadcrumb-item>
          </el-breadcrumb>
          <div style="margin-top:20px;">
              <write-review-item v-for="item in orderItems" :key="item.ID" :item="item"></write-review-item>
              <el-empty v-if="orderItems.length == 0" description="review is already uploaded"></el-empty>
          </div>
      </el-main>
    </template>
  </base-mobile-page>
</template>

<script>
import ModelReview from '../viewmodel/ModelReview.vue'
import BaseMobilePage from '../../components/base/BaseMobilePage.vue'
import WriteReviewItem from '../../components/widgets/WriteReviewItem.vue'

export default {
    extends: ModelReview,
    name: 'review',
    components: {
        BaseMobilePage,
        WriteReviewItem
    }
}
</script>

<style>

</style>